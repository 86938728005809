import { SiteEntry } from "frontend/contentful/schema/universal";
import { SiteHeaderProps } from "design-system/components/universal/site-header/site-header";
import { createNavigationItemProps } from "frontend/contentful/components/universal/navigation-item.props";
import { RenderContentfulRichText } from "../utils/render-contentful-rich-text";
import { createSocialMediaLinksProps } from "./social-media-links.props";
import { createGlobalMenuNavItemProps } from "./global-menu-nav-item.props";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { usePageProps } from "frontend/hooks/use-page-props";
import {
  KnowledgeBaseArticlePageEntry,
  PageEntry,
  ParticipantStoryEntry,
  ClubPageEntry,
} from "frontend/contentful/schema/pages";
import { useContentful } from "frontend/hooks/use-contentful";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import { getSiteHeaderTheme } from "frontend/contentful/components/utils/get-site-header-theme";
import { fetcher, useFetch } from "frontend/hooks/use-fetch";
import { useSearchParams } from "next/navigation";
import { useState } from "react";
import { Suggestions, ModernSearchHit, SearchResults } from "frontend/types";
import { hasSearchData, setdropDownUrl } from "../utils/get-page-search-props";
import { getSiteId } from "frontend/lib/get-site-id";

interface MincAPIResponse {
  result: Array<MincResponseData>;
}

interface MincResponseData {
  sys_updated_on: string;
  type: string;
  details: string;
  cmdb_ci: {
    display_value: string;
    link: string;
  };
  end: string;
  begin: string;
}

export const createSiteHeaderProps = createContentfulProps<
  SiteEntry,
  SiteHeaderProps
>(({ entry, createEditAttributes }) => {
  const searchParams = useSearchParams();

  const pageProps = usePageProps();
  const { getEntryUrl, getEntry, getEntries } = useContentful();
  const [dropdownItems, setDropdownItems] = useState<Array<ModernSearchHit>>(
    [],
  );
  const [dropdownSuggestions, setDropdownSuggestions] =
    useState<Suggestions["updatedSuggestions"]>();
  const site = getEntry(pageProps.site);
  const url = setdropDownUrl(site);

  const handleSearch = async (term: string) => {
    if (term.length < 3 || !term || term === "") {
      setDropdownItems([]);
      return;
    }
    if (term.length < 4 || !term || term === "") {
      setDropdownSuggestions({});
      return;
    }

    const hasSearchPage = getEntry(pageProps.searchPage)?.fields.url;
    const site = getSiteId(entry);

    const data = (await fetcher({
      url: url,
      params: { term, site, hasSearchPage },
    })) as SearchResults | Suggestions;

    if (hasSearchData(data)) {
      if ("hits" in data) {
        setDropdownItems(data.hits.map((hit) => hit as ModernSearchHit));
      }
      if ("updatedSuggestions" in data && data.updatedSuggestions) {
        setDropdownSuggestions(data.updatedSuggestions);
      }
    }

    return;
  };

  const header = getEntry(entry.fields.header);
  if (!header) return;

  let siteUrl = "/";
  if (entry.fields.slug && entry.fields.slug !== "/") {
    siteUrl = `/${entry.fields.slug}`;
  }

  const siteType = entry.fields.domain === "my" ? "internal" : "external";
  const enableLogin = entry.fields.enableLogin;
  const enableLogout =
    pageProps.pagePermissions || pageProps.sitePermissions || enableLogin
      ? true
      : false;

  const globalMenu = getEntry(header.fields.globalMenu);
  const organization = getEntry(globalMenu?.fields.organization);
  const page = pageProps.entry as PageEntry | undefined;
  const isWWWSearchPage =
    page && page.fields.slug === "search" && !page.fields.parentPageId;
  const isParticpantStory = isContentType<ParticipantStoryEntry>(
    "participantStory",
    page,
  );
  const isKnowledgeBaseArticlePage =
    isContentType<KnowledgeBaseArticlePageEntry>(
      "knowledgeBaseArticlePage",
      page,
    );
  const isClubPage = isContentType<ClubPageEntry>("clubPage", page);
  const topper = getEntry(page?.fields.topper);
  const theme = isParticpantStory
    ? "dark"
    : isKnowledgeBaseArticlePage
      ? page.fields.topperTheme
      : isClubPage
        ? "dark"
        : getSiteHeaderTheme(topper);

  const props: SiteHeaderProps = {
    theme: theme,
    items: [],
    isHomepage: pageProps.singletons.isWWWHomePage || isWWWSearchPage,
    globalMenu: {
      id: "global-menu",
      label: "Quick links",
      socialLinks: createSocialMediaLinksProps(organization)?.links,
      items: [],
    },
    searchLink: getEntryUrl(pageProps.searchPage),
    hasSearchOverlay: true,
    search: {
      dropdownItems,
      dropdownSuggestions,
      hasDropdown: true,
      dropdownCta: {
        children: "View Results",
        href: getEntryUrl(pageProps.searchPage),
      },
      handleSearch: (term: string) => {
        void handleSearch(term);
      },
    },
    editAttributes: {
      title: createEditAttributes({ entry: entry, fieldId: "title" }),
      moreItemsTitle: createEditAttributes({
        entry: header,
        fieldId: "moreItemsTitle",
      }),
    },
  };

  if (entry.fields.title) {
    props.sectionTitle = { title: entry.fields.title, url: siteUrl };
  }

  if (pageProps.session) {
    props.session = pageProps.session;
    if (
      entry.fields.domain === "my" &&
      pageProps.session.user?.displayName !== "Anonymous"
    ) {
      props.headerBanner = {
        title: "Hello, " + pageProps.session.user?.displayName,
      };
    }
  }

  getEntries(header.fields.links)?.forEach((link) => {
    const linkProps = createNavigationItemProps(link);
    if (linkProps) props.items?.push(linkProps);
  });

  getEntries(globalMenu?.fields.links)?.forEach((link) => {
    const item = createGlobalMenuNavItemProps(link);
    if (item) props.globalMenu?.items?.push(item);
  });

  const alert = getEntry(header.fields.alert);

  if (alert?.fields.apiSource === "Manual") {
    props.headerAlert = {
      title: alert.fields?.title,
      href: getEntryUrl(alert.fields?.link),
      description: (
        <RenderContentfulRichText document={alert.fields.description} />
      ),
      editAttributes: {
        url: createEditAttributes({ entry: alert.fields.link, fieldId: "url" }),
        title: createEditAttributes({
          entry: header.fields.alert,
          fieldId: "title",
        }),
        description: createEditAttributes({
          entry: header.fields.alert,
          fieldId: "description",
        }),
      },
    };
  } else if (alert?.fields.apiSource === "ServiceNow MINC") {
    /* alert for IT MINCs
    https://www.hbs.edu/api/servicenow/minc
    mock data to test https://www.hbs.edu/api/servicenow/minc?mock=1 */

    const mockParam = searchParams.get("mock");
    const fetchParams = mockParam ? { mock: mockParam } : {};

    const { data } = useFetch<MincAPIResponse>({
      url: "/api/servicenow/minc",
      params: fetchParams,
    });

    if (data?.result?.length) {
      const item = data.result[0];

      if (item?.type === "Service Interruption") {
        props.headerAlert = {
          title: entry.fields.title,
          href: "https://hbs.service-now.com/support",
          description:
            "We are currently experiencing a service interruption related to " +
            item?.cmdb_ci.display_value +
            ".  Please refer to the IT Service Portal for additional details.",
          theme: "red",
        };
      }
    }
  }

  const siteApplicationRedirect = pageProps.siteApplicationRedirect;
  const callToAction = getEntry(header.fields.callToAction);
  const callToActionUrl = getEntryUrl(callToAction);
  const isExternal = callToAction?.fields.isExternalUrl || false;
  if (callToAction) {
    // Use redirect only if an external links
    const link =
      siteApplicationRedirect && callToActionUrl && isExternal
        ? siteApplicationRedirect + "?initurl=" + callToActionUrl
        : callToActionUrl;
    props.cta = {
      text: callToAction.fields.text,
      link: link,
    };
  }

  if (header.fields.moreItemsTitle) {
    props.moreItemsTitle = header.fields.moreItemsTitle;
  }

  if (site && site.fields.title === "Working Knowledge") {
    props.isWorkingKnowledge = true;
    props.workingKnowledgeTagline = header.fields.tagline;
    props.workingKnowledgeTitleLink =
      "https://www.library.hbs.edu/working-knowledge";
  }

  props.siteType = siteType;
  props.enableLogin = enableLogin;
  props.enableLogout = enableLogout;
  props.logoLink =
    siteType === "internal"
      ? "https://inside.hbs.edu/myhbs.aspx"
      : "https://www.hbs.edu";

  return props;
});
