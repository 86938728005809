import { SiteEntry } from "frontend/contentful/schema/universal";
import { Suggestions, PageProps, SearchResults } from "frontend/types";

export const hasSearchData = (data: SearchResults | Suggestions): boolean => {
  if (typeof data === "object") {
    if ("hits" in data) {
      return !!data.hits;
    }
    if ("updatedSuggestions" in data) {
      if (data.updatedSuggestions?.suggestions) {
        return true;
      }
    }
  }
  return false;
};

export const setdropDownActive = (pageProps: PageProps) => {
  if (pageProps.request.domain === "library") return true;
  return false;
};

export const setdropDownUrl = (site?: SiteEntry) => {
  if (site?.fields.title === "Baker Library") {
    return "/api/fetch/baker-search";
  } else if (site?.fields.slug === "knowledge") {
    return "/api/fetch/knowledge-site-search";
  }
  return "/api/fetch/site-search";
};
